<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
        <div class="MBtextcont">
            <p>
                Вас ждет непростой, но очень важный урок. Прям проверка на… Надеюсь, что
                в этом занятии вас ждет много открытий ))
            </p>
            <p>
                Хочу сразу предупредить, я не профессионал, просто хочу лепить голову
                куклы правильно ) Поэтому все мои методы в этом уроке чисто субъективны,
                хотя и взяты из профессиональных источников.
            </p>
            <p>
                От чертежа, который вы построите вместе со мной, мы перейдем к практике
                на пластилине. Это всегда чудо, когда из плоского изображения получается
                объемный вполне реальный объект, да еще и такой симпатичный.
            </p>
             <p>
                Итак, если у вас нет под рукой реального черепа, в смысле модели ) то
                его изображение можно <a target="_blanc" href="https://www.artstation.com/artwork/8ebJLw">скачать и распечатать тут</a>. 
                Так же эти изображения можно найти в любом пособии по
                анатомии, лучше для художников или скульпторов.
            </p>
            <p class="nomargin">Помимо картинок с черепом нам понадобятся:</p>
            <ul>
                <li>Пластилин скульптурный</li>
                <li>Стеки для лепки, нож</li>
                <li>Карандаш, линейка, резинка</li>
                <li>Лист бумаги А4, в клетку или миллиметровка</li>
                <li>Циркуль</li>
                <li>Зубочистки или маленькие палочки (спички)</li>
                <li>Фольга или основа для черепа</li>
            </ul>     
            <h3>1.1 Череп. Основы</h3>
        </div>

        <VideoView video-id="dd53f8d32f68412f8e8f7d1c404101b1"/>

        
        <div class="MBtextcont">
            <a href="/files/FaceAnatomy/Skull1.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/FaceAnatomy/skull1.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист по черепу</h3>
                    <p>Основные составляющие черепа</p>
                </div>
            </a>

            <h3>1.2 Строим чертеж черепа</h3>
        </div>
        <VideoView video-id="5a527507ca3e4f9fb7a6be49f15d26ab"/>

        <div class="MBtextcont">
            <a href="/files/FaceAnatomy/Skull2.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/FaceAnatomy/skull2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Чек-лист "Как построить чертеж"</h3>
                    <p>Пошаговая инструкция</p>
                </div>
            </a>            
            <h3>1.3 Лепим череп из пластилина</h3>
        </div>
        <VideoView video-id="bd84e60e9767492e94915cdb37df8374"/>

        <div class="MBtextcont">   
            <a @click="showImage('/files/FaceAnatomy/Skull3.jpg')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/FaceAnatomy/skull3.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист №3</h3>
                    <p>Разница между женским и мужским черепом</p>
                </div>
            </a>                  
            <h3>1.4 Проверяем симметрию черепа</h3>
        </div>
        <VideoView video-id="1c02ed08c56047e0a3dafacd178dee91"/>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: [
                { title: "Лепим череп", url:"/mycourses/head/1"},
                { title: "Делаем обрубовку", url:"/mycourses/head/2"},
                { title: "Лепим лицо", url:"/mycourses/head/3"},
                { title: "Основа из La Doll", url:"/mycourses/head/4"},
                { title: "Создаем объемы", url:"/mycourses/head/5"},
                { title: "Доработка деталей", url:"/mycourses/head/6"},
            ],
        }
        },
        methods: {
            showImage(src) {
                this.$swal.fire({
                    // width: '80%',
                    height: '90%',
                    background: '#eae2e0',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>